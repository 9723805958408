@use '../../styles/global/colors';
@use '../../styles/global/typography';

.scenario-overview {
  .scenario-overview-card {
    padding: 15px 5px;
    width: calc(100% - 224px);
    z-index: 99;
    background-color: colors.$surface-gray-50;

    .sub-heading {
      font-weight: typography.$font-weight-bold;
      font-size: typography.$font-size-body;
    }

    .scenario-overview-subcard {
      width: 48%;

      .reset-button {
        border: 1px solid colors.$danger-300;
        color: colors.$danger-300;
      }

      .p-selectbutton .p-button.p-highlight {
        background: colors.$global-primary-blue-500;
        border-color: colors.$global-primary-blue-500;
      }

      .p-button:focus {
        box-shadow: none;
      }

      .pi {
        font-weight: 100;
      }

      .p-selectbutton .p-button {
        border: 1px solid colors.$global-primary-blue-500;
      }
    }
  }

  .scenario-overview-table-card {
    padding-left: .1rem;
    padding-top: 70px;
  }
}