@use '../global/colors';
@use '../global/typography';

.icon-gray {
  color: colors.$surface-gray-400;
}

.icon-success {
  color: colors.$success-400;
}

.icon-error {
  color: colors.$danger-400;
}

.icon-slider {
  color: colors.$info-400;
}

.sm-icon {
  font-size: typography.$icon-font-size;
}

.md-icon {
  font-size: typography.$icon-font-size-medium;
}

.icon-info {
  color: colors.$info-500 !important;
}

.icon-primary {
  color: colors.$global-primary-blue-500;
}
