@use '../global/colors';
@use '../global/typography';

.p-inline-message {
  border-radius: 6px;
  &.p-inline-message-info {
    background: colors.$info-100;
    .p-inline-message-icon {
      color: colors.$info-500;
    }
    .p-inline-message-text {
      color: colors.$text-dark;
      font-size: typography.$font-size-small;
    }
  }
  &.md-message {
    height: 53px;
  }
  .p-inline-message-icon {
    font-size: typography.$icon-font-size;
  }
}
