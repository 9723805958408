// Font Families
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700');

$font-family-primary: 'Inter', 'opensans', Arial;


// Heading Sizes
$font-size-heading1: 35px;
$font-size-heading2: 28px;
$font-size-heading3: 24px;
$font-size-heading4: 21px;
$font-size-heading5: 18px;

// Body Sizes
$font-size-body: 16px;
$font-size-small: 14px;
$font-size-extra-small: 12px;

// Icon Sizes
$icon-font-size-large: 80px;
$icon-font-size-medium: 64px;
$icon-font-size: 18px;
$icon-font-size-small: 10px;
$icon-font-size-extra-small: 8px;

// Font Weights
$font-weight-bold: 700;
$font-weight-semibold: 600;
$font-weight-medium: 500;
$font-weight-regular: 400;
$font-weight-light: 300;

