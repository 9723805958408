@use '../global/colors';

.main-content {
  margin-left: 224px;
  margin-top: 50px;
  width: calc(100% - 224px);
  height: calc(100vh - 50px);

  > .page-header {
    border-radius: 0;
    box-shadow: none;
    border-bottom: 1px solid colors.$border-color;
  }

  > div > .p-card-body {
    padding-bottom: 12px;
    padding-top: 15px;
    color: colors.$text-dark;

    > .p-card-content {
      padding: 0px;
      max-height: 92px;

      > h3 {
        padding: 0px;
      }
    }
  }
}

.center-text {
  text-align: center;
}

.p-datatable {
  border: 1px solid colors.$border-color;
}
