@use '../global/colors';
@use '../global/typography';

.custom-tab-view {
  .p-tabview {
    .p-tabview-nav {
      background-color: transparent;
      margin-top: 8px;
      padding-left: 18px;

      // for the highlighted tab
      li.p-highlight .p-tabview-nav-link {
        color: colors.$global-primary-blue-500;
        font-weight: typography.$font-weight-bold;

        background: transparent;
        border-color: colors.$global-primary-blue-500;
        padding: 20px;

        &:focus {
          box-shadow: none;
          background: transparent;
        }

        &:hover {
          background: transparent;
        }
      }

      > li {
        background: transparent;

        &:hover {
          background: transparent;
        }

        &:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
          background-color: transparent;
          box-shadow: none;
        }

        .p-unselectable-text {
          background: transparent;

          &:hover {
            background: transparent;
          }
        }

        .p-tabview-nav-link {
          height: 60px;
          background-color: transparent;
          font-weight: typography.$font-weight-bold;
        }

        .p-badge {
          height: 18px;
          min-width: 18px;
          line-height: 18px;
          font-size: 10px;
        }

        > a {
          &:hover {
            background-color: transparent;
          }
          padding: 20px;
          background: transparent;
        }
      }
    }
    
    .p-tabview-panels {
      margin: 0;
      padding: 0;
      background-color: transparent;
    }
  }
}

