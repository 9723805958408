@use '../global/colors';
@use '../global/typography';

.global-modal {
  width: 600px;
  height: 260px;

  &-header-text {
    color: colors.$text-dark;
    font-size: typography.$font-size-heading5;
  }

  &-body-text {
    font-size: typography.$font-size-body;
    margin-top: 24px;
    color: colors.$text-dark;
    line-height: 24px;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  &-body-content {
    margin: 16px 42px 32px 42px;
  }

  &-icon {
    color: colors.$danger-500;
    font-size: typography.$font-size-heading2;
  }

  .p-dialog-content {
    overflow-y: hidden;
    flex-grow: 1;
  }

  &.custom-body-content {
    height: auto;
    .p-dialog-content {
      overflow-y: auto;
      padding-bottom: 15px;
    }
    .global-modal-body-content {
      margin: 0;
    }
  }
}
