@use '../global/colors';
@use '../global/typography';

.active-menu {
  color: colors.$global-primary-blue-200;
  font-weight: typography.$font-weight-semibold;
  &:before {
    width: 95%;
  }
}

.app-sidebar {
  width: 224px;
  z-index: 999;
  background-color: colors.$global-primary-blue-500;

  .overlay {
    position: fixed;
    display: block;
    width: inherit;
    height: inherit;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: colors.$side-bar-overlay;
    z-index: 1;
  }

  .selector-container {
    min-height: 92px;
    background-color: colors.$global-primary-blue-600;
  }

  .logo-container {
    height: 50px;
    background-color: colors.$global-primary-blue-700;
  }

  nav {
    ul {
      margin: 0;
      padding: 0;
      left: 0;
      li {
        list-style: none;
        a {
          display: block;
          text-decoration: none;
          font-size: typography.$font-size-body;
          color: colors.$white;
          position: relative;
          padding: 15px 15px 15px 16px;
          transition: all 0.2s;
          &.menu-item {
            &.active {
              border-left-style: solid;
              border-width: 5px;
              @extend .active-menu;
            }
            &:hover {
              @extend .active-menu;
            }
          }
        }
        i {
          padding-right: 8px;
        }
        span.station {
          margin-left: 50px;
          font-size: typography.$font-size-extra-small;
          color: colors.$white;
        }
      }
    }
  }
}

@mixin icon($url) {
  &:before {
    content: url($url);
    display: inline-block;
    width: 1rem;
    vertical-align: middle;
  }
}
