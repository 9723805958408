@use '../global/colors';
@use '../global/typography';

.custom-select-btn {
  .p-button {
    border-radius: 20px;
    height: 40px;
    border-color: colors.$text-steel;
    color: colors.$text-steel;
    font-size: typography.$font-size-small;
    font-weight: typography.$font-weight-semibold;
    padding: 0.5rem 2rem;
  }
  &.p-selectbutton {
    .p-button {
      &.p-highlight {
        background: colors.$global-primary-blue-500;
        border-color: colors.$global-primary-blue-500;
      }
      &:hover {
        background: colors.$global-primary-blue-500;
        border-color: colors.$global-primary-blue-500;
      }
      &:not(.p-disabled) {
        &:not(.p-highlight) {
          &:hover {
            background: colors.$global-primary-blue-0;
            border-color: colors.$global-primary-blue-500;
            color: colors.$text-steel;
          }
        }
      }
    }
  }
}
