@use '../global/colors';
@use '../global/typography';

/** These button styles are the based on the AllyIQ Design Library **/
// Primary Button
.primary-button {
  font-weight: typography.$font-weight-semibold;
  background-color: colors.$global-primary-blue-500;
  border-color: colors.$global-primary-blue-500;
  border-radius: 4px !important;

  &.p-button:enabled {
    &:hover {
      background-color: colors.$global-primary-blue-700;
      border-color: colors.$global-primary-blue-700;
    }
    &:focus {
      box-shadow: none;
    }
  }

  i {
    &.left {
      order: -1;
      padding-right: 10px;
    }
    &.right {
      order: 1;
      padding-left: 10px;
    }
  }
}

// Secondary (Outlined) button
.outlined-button {
  &.p-button.p-button-outlined:enabled {
    color: colors.$global-primary-blue-500;
    border-color: colors.$global-primary-blue-500;
    border-width: 1.5px;
    line-height: 1.2;
    font-weight: typography.$font-weight-semibold;

    &:hover {
      color: colors.$global-primary-blue-700;
      box-shadow: none;
    }

    &:focus {
      box-shadow: none;
    }
  }

  &.p-button.p-button-outlined:disabled {
    color: colors.$global-primary-blue-500;
    border-color: colors.$global-primary-blue-500;
    border-width: 1.5px;
    line-height: 1.2;
    font-weight: typography.$font-weight-semibold;
    cursor: default;
    opacity: 0.6;
  }

  i {
    &.left {
      order: -1;
      padding-right: 10px;
    }
  }
}

// Tertiary (Link) Button
.link-button {
  font-weight: typography.$font-weight-semibold;
  &.p-button.p-button-sm {
    .p-button-icon {
      &.xs-icon {
        font-size: 10px;
      }
    }
  }
  &-icon,
  &.p-button .p-button-icon-left {
    font-size: typography.$icon-font-size-small;
  }

  &.p-button.p-button-link {
    color: colors.$global-primary-blue-500;

    &:hover {
      color: colors.$global-primary-blue-700;
      text-decoration: none;

      .p-button-label {
        text-decoration: none;
      }
    }

    &:focus {
      box-shadow: none;
    }
  }

  i {
    &.chevron-icon {
      font-size: 10px;
      padding-top: 5px;
    }

    &.right {
      padding-left: 5px;
    }

    &.left {
      order: -1;
      padding-right: 10px;
    }
  }
}

// Tertiary (Link) Button
.text-button {
  &.p-button.p-button-text {
    color: colors.$global-primary-blue-500;
    &:hover {
      color: colors.$global-primary-blue-500;
    }
    &.p-button-danger {
      color: colors.$danger-300;
    }
  }
  font-weight: typography.$font-weight-semibold;

  i {
    &.left {
      order: -1;
      padding-right: 10px;
    }
  }
}

.no-lateral-padding {
  padding-left: 0;
  padding-right: 0;
}

//Custom Go Button
.primary-go-button {
  font-size: typography.$font-size-small;
  font-weight: typography.$font-weight-semibold;
  background-color: colors.$global-primary-blue-500;
  border-color: colors.$global-primary-blue-500;
  border-radius: 0px;
  padding: 8px 12px !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;

  &.p-button:enabled {
    &:hover {
      background-color: colors.$global-primary-blue-700;
      border-color: colors.$global-primary-blue-700;
    }
    &:focus {
      box-shadow: none;
    }
  }

  i {
    &.left {
      order: -1;
      padding-right: 10px;
    }
  }
}

.p-button {
  border-radius: 4px;
  &.p-button-danger {
    &.p-button-outlined {
      color: colors.$danger-300;
      &:enabled {
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
  &.sm-button {
    height: 36px;
  }

  &:enabled {
    &:focus {
      box-shadow: none;
    }
  }
  &.primary-icon-button {
    &.p-button-text {
      color: colors.$global-primary-blue-500;
      &:enabled {
        &:hover {
          color: colors.$global-primary-blue-500;
        }
      }
    }
  }
  &.error-icon-button {
    &.p-button-text {
      color: colors.$danger-300;
      &:enabled {
        &:hover {
          color: colors.$danger-300;
        }
      }
    }
  }
}

.multi-line-button {
  .primary-label {
    font-weight: typography.$font-weight-medium;
    font-size: typography.$font-size-extra-small;
  }
}
