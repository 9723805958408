@use '../global/colors';
@use '../global/typography';
.p-tag {
  &.p-tag-success-100 {
    background: colors.$success-100;
    color: colors.$text-dark;
  }
  &.p-tag-surface-steel-100 {
    background: colors.$surface-steel-100;
    color: colors.$text-dark;
  }
  &.p-tag-accent-light-wine {
    background: colors.$global-accent-light-wine;
    color: colors.$text-dark;
  }
  &.p-tag-accent-teal {
    background: colors.$global-accent-teal;
    color: colors.$text-white;
  }
  &.p-tag-danger {
    background-color: colors.$danger-100;
    color: colors.$text-dark;
  }
  &.p-tag-gray {
    background-color: colors.$surface-gray-600;
    color: colors.$text-white;
  }
}
