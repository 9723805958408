@use '../../styles/global/colors';

.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: colors.$box-shadow;
  z-index: 9999;
}