@use '../global/colors';
@use '../global/typography';

@mixin font-property($font-size, $font-weight) {
  @extend .normal-font-style;
  font-weight: $font-weight;
  font-size: $font-size;
}

/** Helpers for text classes */
.normal-font-style {
  font-style: normal;
  line-height: normal;
}

.text-dark {
  color: colors.$text-dark;
}

.text-danger {
  color: colors.$danger-500;
}

.text-danger-300 {
  color: colors.$danger-300;
}

.text-gray {
  color: colors.$text-gray;
}

.text-steel {
  color: colors.$text-steel;
}

.primary-text-200 {
  color: colors.$global-primary-blue-200;
}

.primary-text {
  color: colors.$global-primary-blue-500 !important;
}

.label-field {
  .label-text {
    margin-bottom: 8px;
  }
  margin-bottom: 24px;
}

.label-text {
  color: colors.$text-gray;
  font-size: typography.$font-size-body;
}

.detail-text {
  color: colors.$text-dark;
  font-size: typography.$font-size-heading5;
  font-weight: typography.$font-weight-semibold;
}

.text-semibold {
  @include font-property(typography.$font-size-body, typography.$font-weight-semibold);
}

.text-bold {
  @include font-property(typography.$font-size-body, typography.$font-weight-bold);
}

.text-medium {
  @include font-property(typography.$font-size-body, typography.$font-weight-medium);
}

.text-small-light {
  @include font-property(typography.$font-size-small, typography.$font-weight-light);
}

.text-small-medium {
  @include font-property(typography.$font-size-small, typography.$font-weight-medium);
}

.text-small-regular {
  @include font-property(typography.$font-size-small, typography.$font-weight-regular);
}
.text-small-semibold {
  @include font-property(typography.$font-size-small, typography.$font-weight-semibold);
}
.text-body-semibold {
  @include font-property(typography.$font-size-body, typography.$font-weight-semibold);
}
.text-extra-small-regular {
  @include font-property(typography.$font-size-extra-small, typography.$font-weight-regular);
}
.text-extra-small-medium {
  @include font-property(typography.$font-size-extra-small, typography.$font-weight-medium);
}
.text-extra-small-semibold {
  @include font-property(typography.$font-size-extra-small, typography.$font-weight-semibold);
}
