@use '../global/colors';
@use '../global/typography';

.toolbar-container {
  position: fixed;
  z-index: 998;
  top: 0;
  left: 0;
  width: 100%;
}

.p-toolbar {
  border-top: none !important;
  border-bottom: 1px solid colors.$surface-gray-300;
  background: colors.$global-primary-blue-0 !important;
  border-radius: 0px !important;
  height: 50px;
  padding: 0 16px;
}

.logout-confirm-modal {
  width: 600px;
  height: 200px;
  .global-modal-body-content {
    margin: 0px 0px 0px 10px;
  }
}
.logout-spinner-modal {
  width: 228px;
  height: 125px;
  .global-modal-body-content {
    margin: 10px 0px 0px 0px;
  }
}
