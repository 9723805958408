@use '../global/colors';
@use '../global/typography';

.p-datatable {
  .p-paginator {
    justify-content: end;
    border-width: 1px 0 0 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    .p-paginator-current {
      color: colors.$surface-steel-500;
      font-size: typography.$font-size-small;
    }
    .p-paginator-pages {
      .p-paginator-page {
        border-radius: 50%;
        &.p-highlight {
          background: transparent;
          border-color: transparent;
          color: colors.$surface-steel-300;
        }
        font-size: typography.$font-size-small;
        color: colors.$global-primary-blue-500;
      }
    }
    .p-paginator-element {
      color: colors.$global-primary-blue-500;
      &.p-disabled {
        color: colors.$text-gray;
        opacity: 1;
      }
    }

    .p-dropdown {
      border-color: colors.$surface-gray-300;
      .p-dropdown-label {
        color: colors.$text-gray;
      }

      .p-dropdown-trigger {
        color: colors.$text-dark;
        .p-icon {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}
