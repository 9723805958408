@import './styles/global/headings';
@import './styles/global/card';
@import './styles/global/icon';
@import './styles/global/inline-message';
@import './styles/global/tags';
@import './styles/global/data-table-paginator';
@import './styles/global/select-button';
@import './styles/global/dialog';
@import './styles/global/panel';
@import './styles/global/tab-view';
@import './styles/global/text';
@import './styles/global/badge';

.App {
  text-align: center;
}

.App-logo img {
  padding-top: 5px;
  width: 100%;
  height: 90%;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body {
  background-color: #f7f7f7;
  font-family: "Inter", sans-serif;
  margin: 0px;
  padding: 0px;
  border: 0px;
  width: 100%;
  height: 100%;
}
