@use '../global/colors';
@use '../global/typography';

.logout-modal {
  min-width: 22vw;
  min-height: 14vh;

  > h6 {
    line-height: 17px;
    font-size: typography.$font-size-small;
  }

  > div > h1 {
    line-height: 40px;
  }

  &.p-dialog {
    .p-dialog-content {
      padding-bottom: 0;
      padding-top: 16px;
    }

    .p-dialog-header .p-dialog-title {
      font-size: typography.$font-size-heading5;
      line-height: 21px;
      font-weight: typography.$font-weight-bold;
    }

    .p-dialog-footer {
      border-bottom-right-radius: 0.375rem;
      border-bottom-left-radius: 0.375rem;
    }
  }
}
