// Global Colors
$global-primary-blue-0: #ffffff;
$global-primary-blue-25: #f8fafc;
$global-primary-blue-50: #e9eef4;
$global-primary-blue-100: #c9d5e3;
$global-primary-blue-200: #9fb4ce;
$global-primary-blue-300: #7391b7;
$global-primary-blue-400: #4870a1;
$global-primary-blue-500: #095192;
$global-primary-blue-600: #1b4577;
$global-primary-blue-700: #173a63;
$global-primary-blue-800: #122e50;
$global-primary-blue-900: #0e243f;

$global-accent-midnight: #2c367f;
$global-accent-light-midnight: #cccfe0;
$global-accent-wine: #802857;
$global-accent-light-wine: #ecdfe6;
$global-accent-teal: #449190;
$global-accent-light-teal: #c5dddd;
$global-accent-yellow: #ffe14d;
$global-accent-light-yellow: #fff6c8;
$global-accent-cyan: #06b6d4;
$global-accent-light-cyan: #c3edf5;
$global-accent-orange: #db5b07;
$global-accent-light-orange: #f6d8c3;

// Text
$text-dark: #25282a;
$text-gray: #767676;
$text-light: #7e7e7e;
$text-white: #ffffff;
$text-blue: #095192;
$text-steel: #64748b;

// Surfaces
$surface-gray-0: #ffffff;
$surface-gray-50: #fafafa;
$surface-gray-100: #f5f5f5;
$surface-gray-200: #eeeeee;
$surface-gray-300: #e0e0e0;
$surface-gray-400: #bdbdbd;
$surface-gray-500: #9e9e9e;
$surface-gray-600: #757575;
$surface-gray-700: #616161;
$surface-gray-800: #424242;
$surface-gray-900: #212121;

$surface-steel-50: #f7f8f9;
$surface-steel-100: #dadee3;
$surface-steel-200: #bcc3cd;
$surface-steel-300: #9fa9b7;
$surface-steel-400: #818ea1;
$surface-steel-500: #64748b;
$surface-steel-600: #556376;
$surface-steel-700: #465161;
$surface-steel-800: #37404c;
$surface-steel-900: #282e38;

// Messages
$success-50: #e6f6f4;
$success-100: #d9f2ef;
$success-200: #b0e4dd;
$success-300: #00a991;
$success-400: #009883;
$success-500: #008774;
$success-600: #007f6d;
$success-700: #006557;
$success-800: #004c41;
$success-900: #003b33;

$warning-50: #faf3e9;
$warning-100: #f7edde;
$warning-200: #efdabb;
$warning-300: #cc8925;
$warning-400: #b87b21;
$warning-500: #a36e1e;
$warning-600: #99671c;
$warning-700: #7a5216;
$warning-800: #5c3e11;
$warning-900: #47300d;

$danger-50: #fbebea;
$danger-100: #f9e1df;
$danger-200: #f3c0bd;
$danger-300: #d9342b;
$danger-400: #c32f27;
$danger-500: #ae2a22;
$danger-600: #a32720;
$danger-700: #821f1a;
$danger-800: #621713;
$danger-900: #4c120f;

$info-50: #f6eefe;
$info-100: #ead6fd;
$info-200: #dab6fc;
$info-300: #c894fa;
$info-400: #b874f8;
$info-500: #a855f7;
$info-600: #8f48d2;
$info-700: #773caf;
$info-800: #60308d;
$info-900: #4c266f;

// Borders, surfaces and shadows
$border-color: $surface-gray-300;
$input-border-color: #ced4da;
$border-dark-color: #d1d3d4;
$white: $surface-gray-0;
$box-shadow: rgba(0, 0, 0, 0.2);
$side-bar-overlay: rgba(0, 0, 0, 0.4);
$box-shadow-widget: 0px 2.11511px 1.05755px -1.05755px rgba(0, 0, 0, 0.02),
  0px 1.05755px 1.05755px 0px rgba(0, 0, 0, 0.14), 0px 1.05755px 3.17266px 0px rgba(0, 0, 0, 0.12);
// Buttons
$button-blue-default: $global-primary-blue-500;
$button-blue-hover: $global-primary-blue-700;
$button-blue-active: $global-primary-blue-800;
$button-blue-disabled: rgba($global-primary-blue-500, 0.7);

//image
$image-dark-overlay: #00000050;

//Charts
$chart-blue-dark: #212967;
$chart-blue-light: #1e37ecad;
$chart-brown-dark: $danger-500;
$chart-brown-light: #ae2a22ad;
$chart-purple-dark: #48256a;
$chart-purple-light: #af69f1;

/*********************************************************
// DEPRECATED - THESE SHOULD BE REPLACED BY VALUES ABOVE 
*********************************************************/
// Button Colors
$success-button-color: #518c20;
