@use '../global/colors';

.home-container {
  display: flex;
  height: 100vh;
}

.login-content {
  margin: 15px;
  height: 95%;
  width: 100%;
  position: relative;
}

.login-content h1 {
  color: colors.$global-primary-blue-500;
}
