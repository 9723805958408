@use '../global/colors';

.p-dialog {
  &.border-header {
    .p-dialog-header {
      border-bottom: 1px solid colors.$border-color;
    }
  }
  &.shadow-footer {
    .p-dialog-footer {
      z-index: 1;
      border-top: 1px solid colors.$border-color;
      box-shadow: 0px -4px 8px colors.$box-shadow;
      padding: 15px;
    }
  }
}
