@use '../global/colors';
@use '../global/typography';

%border-bottom {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.custom-expand-panel {
  &.p-panel {
    border-radius: 4px;
    border: 1px solid colors.$surface-gray-300;
    background-color: colors.$global-primary-blue-25;
    .custom-panel-header-card {
      background-color: colors.$global-primary-blue-0;
      border-radius: 4px;
    }
    .p-panel-content {
      padding: 0;
      border: 0;
      > :last-child {
        @extend %border-bottom;
      }
      .p-datatable {
        border: none;
        border-top: 1px solid colors.$surface-gray-300;
        .p-datatable-wrapper {
          @extend %border-bottom;
        }
        .p-datatable-thead > tr > th {
          background: colors.$surface-gray-200;
        }
        .p-datatable-tbody > tr {
          background: colors.$global-primary-blue-25;
        }
      }
    }
  }
}
