@use '../global/colors';
@use '../global/typography';

@mixin font-property($font-size, $font-weight) {
  @extend .normal-font-style;
  font-weight: $font-weight;
  font-size: $font-size;
}

/** Helpers for text classes */
.normal-font-style {
  font-style: normal;
  line-height: normal;
}

.h3-bold {
  @include font-property(typography.$font-size-heading3, typography.$font-weight-bold);
}
.h5-bold {
  @include font-property(typography.$font-size-heading5, typography.$font-weight-bold);
}

.h4-bold {
  @include font-property(typography.$font-size-heading4, typography.$font-weight-bold);
}

.h5-semibold {
  @include font-property(typography.$font-size-heading5, typography.$font-weight-semibold);
}
.header-font-black-small {
  @extend .normal-font-style;

  color: colors.$text-dark;
  font-weight: typography.$font-weight-semibold;
  font-size: typography.$font-size-small;
}

.header-font-black-body {
  @extend .normal-font-style;

  color: colors.$text-dark;
  font-weight: typography.$font-weight-semibold;
  font-size: typography.$font-size-body;
}

.header-font-black-heading4 {
  @extend .normal-font-style;

  color: colors.$text-dark;
  font-weight: typography.$font-weight-semibold;
  font-size: typography.$font-size-heading4;
}

.header-font-primary-heading4 {
  @extend .normal-font-style;

  color: colors.$global-primary-blue-600;
  font-weight: typography.$font-weight-semibold;
  font-size: typography.$font-size-heading4;
}

.header-font-black-heading5 {
  @extend .normal-font-style;

  color: colors.$text-dark;
  font-weight: typography.$font-weight-semibold;
  font-size: typography.$font-size-heading5;
}

.header-font-black-bold-heading5 {
  @extend .normal-font-style;

  color: colors.$text-dark;
  font-weight: typography.$font-weight-bold;
  font-size: typography.$font-size-heading5;
}

.header-font-black-medium-heading5 {
  @extend .normal-font-style;

  color: colors.$text-dark;
  font-weight: typography.$font-weight-medium;
  font-size: typography.$font-size-heading5;
}


.header-font-black-regular {
  @extend .normal-font-style;

  color: colors.$text-dark;
  font-weight: typography.$font-weight-regular;
  font-size: typography.$font-size-body;
}

.header-font-black-medium-body {
  @extend .normal-font-style;

  color: colors.$text-dark;
  font-weight: typography.$font-weight-medium;
  font-size: typography.$font-size-body;
}

.normal-font-small {
  @extend .normal-font-style;

  color: colors.$text-gray;
  font-weight: typography.$font-weight-medium;
  font-size: typography.$font-size-small;
}

.normal-font-black-small {
  @extend .normal-font-style;

  color: colors.$text-dark;
  font-weight: typography.$font-weight-regular;
  font-size: typography.$font-size-small;
}

.normal-font-extra-small {
  @extend .normal-font-style;

  color: colors.$text-gray;
  font-weight: typography.$font-weight-medium;
  font-size: typography.$font-size-extra-small;
}

.normal-font-black-extra-small {
  @extend .normal-font-style;

  color: colors.$text-dark;
  font-weight: typography.$font-weight-medium;
  font-size: typography.$font-size-extra-small;
}

.normal-font-bold-extra-small {
  @extend .normal-font-style;

  color: colors.$text-dark;
  font-weight: typography.$font-weight-bold;
  font-size: typography.$font-size-extra-small;
}

.light-font-small {
  @extend .normal-font-style;

  color: colors.$text-steel;
  font-weight: typography.$font-weight-semibold;
  font-size: typography.$font-size-small;
}

.light-font-extra-small {
  @extend .normal-font-style;

  color: colors.$text-steel;
  font-weight: typography.$font-weight-medium;
  font-size: typography.$font-size-extra-small;
}

.subheader-font-normal-small {
  @extend .normal-font-style;
  color: colors.$text-gray;
  font-weight: typography.$font-weight-semibold;
  font-size: typography.$font-size-small;
}

.subheader-font-black-extra-small {
  @extend .normal-font-style;

  color: colors.$text-dark;
  font-weight: typography.$font-weight-semibold;
  font-size: typography.$font-size-extra-small;
}

.header-font-white-regular {
  @extend .normal-font-style;

  color: colors.$text-white;
  font-weight: typography.$font-weight-regular;
  font-size: typography.$font-size-body;
}

.header-font-blue-normal-small {
  @extend .normal-font-style;
  color: colors.$text-blue;
  font-weight: typography.$font-weight-semibold;
  font-size: typography.$font-size-small;
}

.header-font-white-normal-small {
  @extend .normal-font-style;
  color: colors.$text-white;
  font-weight: typography.$font-weight-semibold;
  font-size: typography.$font-size-small;
}
