@use '../../styles/global/colors';

.p-inputtext {
  font-size: 0.75rem;
}

.p-slider .p-slider-range {
  background: colors.$global-primary-blue-500;
}

.p-column-title {
  width: 100%;
}

.p-slider .p-slider-handle {
  background: url("../../assets/images/track.svg");
  background-size: cover;
  background-position: center;
  border: none;
  height: 1.5rem;
  border-radius: 0%;
}

.p-slider .p-slider-handle:focus {
  box-shadow: none;
}

.p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: url("../../assets/images/track.svg");
  background-size: cover;
  background-position: center;
  border: none;
}

.p-slider.p-slider-horizontal .p-slider-handle {
  margin-top: -0.75rem;
}

.p-slider-horizontal {
  cursor: grab !important;
}

.lock {
  opacity: 0.5;
  color: red;
  font-weight: 600;
}

.unlock {
  color: colors.$global-primary-blue-500;
  font-weight: 600;
}

.scenario-definition-table {
  .p-datatable-table {
    th {
      padding: .5rem !important;
    }

    td {
      padding: 0 .5rem !important;
    }
  }
}

.button-background {
  background-color: inherit;
}