@use '../../styles/global/colors';
@use '../../styles/global/typography';


.home {
  .category-card {
    border: 1px solid colors.$border-color;
    background-color: colors.$surface-gray-0;
    margin-bottom: .6rem;

    label {
      font-weight: typography.$font-weight-semibold;
      font-size: typography.$font-size-small;
    }

    .table-container {

      .table-card {
        margin-bottom: 15px;
      }

      .table-card:last-child {
        margin-bottom: 0px;
      }
    }
  }

  .submit-button {
    text-transform: capitalize !important;
  }

  .p-button.p-button-sm .p-button-icon {
    font-size: 0.75rem;
  }

  .pi {
    font-size: 0.75rem;
    font-weight: 700;
  }
}