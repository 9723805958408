@use '../global/colors';
@use '../global/typography';

.p-overlay-badge {
  .p-badge {
    background-color: colors.$info-500;
    position: relative;
    top: -5px;
    right: 5px;
  }
}
