@use '../global/colors';
@use '../global/typography';

@mixin toast-msg($border-color, $icon-color, $bg-color) {
  margin-left: 220px;
  border: solid $border-color;
  border-width: 0 0 0 8px;
  box-shadow: none;
  border-radius: 6px;
  i.pi {
    color: $icon-color;
    font-size: 21px;
  }
  background-color: $bg-color;
}

.p-toast {
  //Generic values
  min-width: 75%;
  max-width: 75%;

  .p-toast-summary {
    color: colors.$text-dark;
    font-weight: typography.$font-weight-bold;
    padding-right: 10px;
  }

  .p-toast-message {
    //On Error
    &.p-toast-message-error {
      @include toast-msg(colors.$danger-300, colors.$danger-300, colors.$danger-100);
    }

    //On Success
    &.p-toast-message-success {
      @include toast-msg(colors.$success-400, colors.$success-600, colors.$success-100);
    }

    //On Warning
    &.p-toast-message-warn {
      @include toast-msg(colors.$warning-400, colors.$warning-400, colors.$warning-100);
    }

    //On Info
    &.p-toast-message-info {
      @include toast-msg(colors.$info-600, colors.$info-100, colors.$info-600);
    }

    .p-toast-message-content {
      padding: 16px 24px;
      align-items: center;
      .p-toast-detail {
        color: colors.$text-dark;
        font-weight: typography.$font-weight-regular;
        display: inline-block;
        margin: auto;
      }
    }
  }

  .p-icon .p-toast-message-icon {
    color: colors.$warning-400;
  }

  .p-toast-message.p-toast-message-error .p-toast-icon-close {
    color: colors.$text-dark;
  }
}
