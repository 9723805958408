@use '../global/colors';
@use '../global/typography';

.shopping-cart-page {
  background-color: colors.$surface-gray-50;

  &-header-text {
    font-size: typography.$font-size-body;
  }

  &-header-value {
    font-size: typography.$font-size-heading4;
  }

  &-divider {
    height: 21px;
    color: colors.$surface-steel-200;
  }

}

.shopping-cart-search {
  background-color: colors.$surface-gray-100;
  padding: 12px 24px;
  border: 1px solid colors.$border-color;
  border-radius: 4px;

  .p-input-icon-left > .p-inputtext {
    padding-left: 36px;
  }

  &-input {
    width: 140%;
    padding: 12px;
    margin-right: 12px;
  }
}
