.scenario-overview-table {

  .p-datatable-thead>tr>th,
  .table-header {
    text-align: end;
  }

  .p-datatable .p-datatable-tbody>tr>td {
    padding: .3rem 1rem;
  }
}