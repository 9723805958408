@use './styles/global/colors';
@use './styles/global/typography';

body {
  margin: 0;
  padding: 0;
  background-color: colors.$surface-gray-50;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bg-light {
  background-color: colors.$surface-gray-50;
}

.bg-primary-blue-25 {
  background-color: colors.$global-primary-blue-25;
}

.bg-primary-blue-50 {
  background-color: colors.$global-primary-blue-50;
}

.bg-info-100 {
  background-color: colors.$info-100;
}

.flagged {
  background-color: colors.$danger-100;
}

.bg-success-100 {
  background-color: colors.$success-100;
}

.bg-gray-300 {
  background-color: colors.$surface-gray-300;
}

.bg-surface-gray-0 {
  background: colors.$surface-gray-0;
}
:host,
:root {
  font-family: typography.$font-family-primary;
  .p-component {
    font-family: typography.$font-family-primary;
  }
}

/* Input Text Field - active & hover */

.p-inputtext {
  border-radius: 4px;
  background: colors.$surface-gray-0;
}

.p-inputtext::placeholder {
  color: colors.$surface-steel-300;
}

.p-inputtext:enabled:hover {
  border: 1px solid colors.$surface-steel-400;
}

.p-inputtext.input-error:enabled:hover {
  border: 1px solid colors.$danger-300;
}

.p-inputtext.input-error:enabled:focus {
  border: 0.1px solid colors.$danger-300;
  box-shadow: colors.$danger-300 0px 0px 2.5px 0.6px;
}

.p-inputtext:enabled:focus {
  border: 0.1px solid colors.$surface-steel-400;
  box-shadow: colors.$global-primary-blue-500 0px 0px 2.5px 0.6px;
}

/* Form Group*/
.form-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 1.5rem;
  label {
    font-weight: typography.$font-weight-medium;
    color: colors.$text-dark;
  }
  .p-inputtext {
    color: colors.$text-dark;
  }
  &.error {
    label {
      color: colors.$danger-300;
    }
    .p-inputtext {
      color: colors.$danger-300;
    }
    .p-component {
      border-color: colors.$danger-300;
    }
  }
}

/*Calendar*/
.date-calendar {
  height: fit-content;
}

/* Error field */
.input-error {
  border: 1px solid colors.$danger-300;
  color: colors.$danger-300;
}

/* Input Dropdown Field - active & hover */

.p-dropdown {
  border-radius: 4px;
  background: colors.$surface-gray-0;
}

.p-dropdown .p-dropdown-label.p-placeholder {
  color: colors.$surface-steel-300;
}

.p-dropdown:not(.p-disabled):hover {
  border: 1px solid colors.$surface-steel-400;
}

.p-dropdown:not(.p-disabled).p-focus {
  border: 0.1px solid colors.$surface-steel-400;
  box-shadow: colors.$global-primary-blue-500 0px 0px 2.5px 0.6px;
}

/* Radio button checked style */
.p-radiobutton {
  .p-radiobutton-box {
    &.p-highlight {
      border-color: colors.$global-primary-blue-500;
      background: colors.$global-primary-blue-0;
    }
    .p-radiobutton-icon {
      background-color: colors.$global-primary-blue-500;
    }
  }
}
.p-checkbox {
  .p-checkbox-box {
    border-color: colors.$surface-gray-400;
    border-radius: 4px;
  }
  & .p-checkbox-box.p-highlight {
    background: colors.$global-primary-blue-500;
    border-color: colors.$global-primary-blue-500;
    &:hover {
      box-shadow: none;
    }
    &.p-focus {
      background: colors.$global-primary-blue-500;
    }
  }
  &:not(.p-checkbox-disabled) .p-checkbox-box {
    &.p-highlight:hover {
      background: colors.$global-primary-blue-500;
      border-color: colors.$global-primary-blue-500;
    }
    &.p-focus {
      box-shadow: none;
      border-color: colors.$surface-gray-400;
    }
    &:hover {
      border-color: colors.$global-primary-blue-500;
    }
  }
}
/** disable scrolling behavior */
.disable-scrolling {
  overflow: hidden;
}

/** enable scrolling behavior */
.enable-scrolling {
  overflow: auto;
}

/* Datepicker */
.p-calendar {
  .p-datepicker-trigger {
    background-color: colors.$white;
    border-color: colors.$input-border-color;
    border-left-width: 0px;
    color: colors.$surface-gray-500;

    &:hover {
      background-color: colors.$surface-gray-100;
      border-color: colors.$input-border-color;
      color: colors.$surface-gray-500;
    }
  }
}

/* Border */
.border-light {
  border-color: colors.$border-color;
}

.border-dark {
  border-color: colors.$border-dark-color;
}

.border-line-light {
  border-color: colors.$border-color;
  border: 1px solid colors.$border-color;
}

$dash-length: 20px;
.dashed-border-lg {
  $background-image: repeating-linear-gradient(
      0deg,
      colors.$surface-gray-200,
      colors.$surface-gray-200 $dash-length,
      transparent $dash-length,
      transparent $dash-length * 2,
      colors.$surface-gray-200 $dash-length * 2
    ),
    repeating-linear-gradient(
      90deg,
      colors.$surface-gray-200,
      colors.$surface-gray-200 $dash-length,
      transparent $dash-length,
      transparent $dash-length * 2,
      colors.$surface-gray-200 $dash-length * 2
    ),
    repeating-linear-gradient(
      180deg,
      colors.$surface-gray-200,
      colors.$surface-gray-200 $dash-length,
      transparent $dash-length,
      transparent $dash-length * 2,
      colors.$surface-gray-200 $dash-length * 2
    ),
    repeating-linear-gradient(
      270deg,
      colors.$surface-gray-200,
      colors.$surface-gray-200 $dash-length,
      transparent $dash-length,
      transparent $dash-length * 2,
      colors.$surface-gray-200 $dash-length * 2
    );
  background-image: $background-image;
  background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
}

.p-divider {
  &.p-divider-vertical {
    &:before {
      border-left: 1px solid colors.$surface-gray-200;
    }
  }
}

h1 {
  font-size: typography.$font-size-heading1;
}

h2 {
  font-size: typography.$font-size-heading2;
}

h3 {
  font-size: typography.$font-size-heading3;
  line-height: 29.65px;
}

h4 {
  font-size: typography.$font-size-heading4;
}

h5 {
  font-size: typography.$font-size-heading5;
}

.body-text {
  font-size: typography.$font-size-body;
}

.small-text {
  font-size: typography.$font-size-small;
}

.xs-text {
  font-size: typography.$font-size-extra-small;
}

.img-flip {
  transform: scaleX(-1);
}

/* Width */
.min-w-350 {
  min-width: 350px;
}

.disabled {
  cursor: default;
  opacity: 0.5;
}

:root {
  --accent-wine: #{colors.$global-accent-wine};
  --accent-orange: #{colors.$global-accent-orange};
  --accent-teal: #{colors.$global-accent-teal};
  --surface-gray-300: #{colors.$surface-gray-300};

  --chart-blue-dark: #{colors.$chart-blue-dark};
  --chart-blue-light: #{colors.$chart-blue-light};
  --chart-brown-dark: #{colors.$chart-brown-dark};
  --chart-brown-light: #{colors.$chart-brown-light};
  --chart-purple-dark: #{colors.$chart-purple-dark};
  --chart-purple-light: #{colors.$chart-purple-light};

  --font-family-primary: #{typography.$font-family-primary};
  --font-weight-semibold: #{typography.$font-weight-semibold};
  --font-size-heading3: #{typography.$font-size-heading3};
}
