@use '../global/colors';
@use '../global/typography';

.card-gray {
  background-color: colors.$surface-gray-100;
  border: 1px solid colors.$surface-gray-300;
  border-radius: 6px;
  padding: 10px 24px;
}

.card-light {
  border-radius: 4px;
  border: 1px solid colors.$surface-gray-200;
  background-color: colors.$global-primary-blue-0;
  .footer {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.card-light-blue {
  border-radius: 4px;
  border: 1px solid colors.$surface-gray-300;
  background-color: colors.$global-primary-blue-25;
}

.card-primary {
  border-radius: 4px;
  border: 1px solid colors.$global-primary-blue-500;
}

.card-primary-blue {
  border-radius: 4px;
  border: 1px solid colors.$global-primary-blue-500;
  background-color: colors.$global-primary-blue-50;
}
