@use '../../styles/global/colors';
@use '../../styles/global/typography';

.graph-box {
  margin: 0 !important;
  padding-top: 64px;

  .col-6 {
  padding: .1rem;
  }

  .graph-container {
    background-color: colors.$surface-gray-0;
    border: 2px solid colors.$input-border-color;
    padding: 0px 10px;
    height: calc(100vh - 270px);

    .graph-heading-box {
      margin: 20px 5px;

      .graph-heading-text {
        font-size: typography.$font-size-small;
        font-weight: typography.$font-weight-bold;
      }
    }

    .data-not-found {
      color: colors.$danger-300;
      font-weight: typography.$font-weight-bold;
      height: calc(100vh - 380px);
    }
  }
}